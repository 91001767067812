import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Logo from '../Image/pic.png';

const Header = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="bg-light">
      <Container className='mobile-container'>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center">
            <img src={Logo} alt="College Logo" height="80px" className="mr-3" />
            <div>
              <h1 className="mb-0">Calcutta Girls' College</h1>
              <p className="pl-1">
              
                 NAAC Accredited B+ (3rd Cycle) | NIRF | ISO Certified |EMS QMS CERTIFICATION
                 
              </p>
             
              
            </div>
            <div style={{ display: 'inline-block' }}>
            <Link to="https://cu.onlineadmission.org/ghc/admission_notification_ug.aspx?clgcode=CGCL">
              <Button
                variant="primary"
                style={{
                  position: isMobile ? 'relative' : 'absolute',
                  top: isMobile ? 'unset' : '0',
                  right: isMobile ? 'unset' : '0',
                  marginTop: isMobile ? '15px' : '50px',
                  marginLeft: isMobile ? '0' : '2px',
                  marginRight: isMobile ? '0' : '50px',
                }}
              >
                Online Admission 2024
              </Button>
              <br></br>
            </Link>
           
            <Link to='https://www.feepayr.com/'>
            <Button
                variant="primary"
                style={{
                  position: isMobile ? 'relative' : 'absolute',
                  top: isMobile ? 'unset' : '0',
                  right: isMobile ? 'unset' : '0',
                  marginTop: isMobile ? '15px' : '90px',
                  marginLeft: isMobile ? '5px' : '20px',
                  marginRight: isMobile ? '0px' : '80px',
                }}
              >Pay Semester Fees</Button>
            </Link>
            </div>
          </div>
        </div>
      </Container>
      <br></br>
      {!isMobile && (
        <div className="position-fixed top-0 right-0 mt-0 mr-0" style={{ position: 'absolute', top: 0, right: 0 }}>
          <Button
            variant="secondary"
            className="custom-button"
            style={{
              position: 'sticky',
              top: 0,
              right: 0,
              zIndex: 1,
              borderTopLeftRadius: '0',
              borderTopRightRadius: '0',
            }}
          >
            Staff Login
          </Button>
          <Button
            variant="secondary"
            className="custom-button"
            style={{
              position: 'sticky',
              top: 0,
              right: 0,
              zIndex: 1,
              borderTopLeftRadius: '0',
              borderTopRightRadius: '0',
              marginTop: '-1px',
            }}
          >
            Students Login
          </Button>
        </div>
      )}
      {isMobile && (
        <div className="d-md-none position-fixed top-0 right-0 mr-4" style={{ position: 'absolute', top: 0, right: 0 }}>
          {showDrawer ? (
            <>
              <Button
                variant="secondary"
                className="custom-button"
                style={{
                  position: 'sticky',
                  top: 0,
                  right: 0,
                  zIndex: 1,
                  borderTopLeftRadius: '0',
                  borderTopRightRadius: '0',
                }}
              >
                Staff Login
              </Button>
              <Button
                variant="secondary"
                className="custom-button"
                style={{
                  position: 'sticky',
                  top: 0,
                  right: 0,
                  zIndex: 1,
                  borderTopLeftRadius: '0',
                  borderTopRightRadius: '0',
                  marginTop: '-1px',
                }}
              >
                Students Login
              </Button>
              <BsCaretUpFill className="sticky-arrow" onClick={toggleDrawer} />
            </>
          ) : (
            <BsCaretDownFill className="sticky-arrow" onClick={toggleDrawer} />
          )}
        </div>
      )}
      <style>{`
        .mobile-container {
          padding-top: 0 !important;
        }

        .custom-button {
          position: sticky;
          top: 0;
          right: 0;
          z-index: 1;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-left: 2px;
          margin-right: 2px;
        }

        .sticky-arrow {
          font-size: 24px;
          color: #007bff;
          cursor: pointer;
          marginTop: 5px;
        }
      `}</style>
    </header>
  );
};

export default Header;
