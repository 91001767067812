import React, { useState, useRef, useEffect } from 'react';
// import image1 from '../../Image/popup.png';
import '../homepage/Modal.css';
// import { useNavigate } from 'react-router-dom';
// import Table from '../table';
// import filteredData from '../../resources/modalContent.json';

const ModalComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const popupRef = useRef(null);

  const handleButtonClick = () => {
    // Open the link in a new window
    window.open('https://cu.onlineadmission.org/ghc/admission_notification_ug.aspx?clgcode=CGCL', '_blank');
  };

  // Close the modal when clicking outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setModalIsOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setModalIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      {modalIsOpen && (
        <div className="lppopup" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="lppopup-content" ref={popupRef} style={{ position: 'relative' }}>
            {/* Close button styled as a "dinosaur" */}
            <button
              type="button"
              className="close"
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '-15%',
                right: '-2%',
                fontSize: '2rem', // Large size for the "dinosaur"
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: '#000', // Adjust color if needed
                padding: '10px',
              }}
            >
              &times;
            </button>

            {/* Header for ONLINE ADMISSION */}
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
              ONLINE ADMISSION 2024
            </h2>

            {/* Button that opens the link */}
            <div style={{ textAlign: 'center' }}>
              <button className="btn btn-primary" onClick={handleButtonClick}>
                Visit Admission Portal
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalComponent;
